import * as React from "react"
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import {colors, device} from './styles/GlobalStyles'

interface CardProps {
  Image?: string
  Title?: string
  Body: string
  ButtonText: string
  ButtonURL?: any
}

export const CtaCard: React.FC<CardProps> = ({ Image, Title, Body, ButtonText, ButtonURL }) => (
  <StyledContainer className="card" > 
    <StyledTitlePhoto className="image" src={Image}/>
    <TitleText className="title">
      {Title}
    </TitleText>
    <Description>
      {documentToReactComponents(JSON.parse(Body))}
    </Description> 
    <ButtonDescription className="buttonText" to={ButtonURL} >
      {ButtonText}
    </ButtonDescription>
  </StyledContainer>
)

const StyledContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  position: static;
  overflow: hidden;
  background: transparent;
  opacity: 0.8;
  color: ${colors.darkGrey};
  border-top: 1px solid #3D5386;
  border-bottom: 1px solid #3D5386;
  border-right: transparent;
  border-left: transparent;
  outline: 0;
  text-align: center;
  justify-content: space-between;

  @media ${device.laptop} {
    width: 446px;
    padding: 40px 45px 48px;

    &:nth-child(2){
      border-left: 1px solid #3D5386;
      border-right 1px solid #3D5386;
    }
  }
`

const TitleText = styled.h1`
  position: static;
  font-family: "poppins";
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  color: ${colors.white};
  flex: none;
  margin: 24px 0px 0px 0px;
`

const Description = styled.p`
  margin: 0px;
  padding: 0px;
  p {
    font-family: "poppins";
    font-size: 18px;
    line-height: 140%;
    color: ${colors.lightGrey};
    flex: none;
    margin: 8px 0px 24px 0px;
  }
  b {
    color: #fff;
  }

  @media ${device.laptop} {
    p {
      width: 357px;
    }
  }
`

const StyledTitlePhoto = styled.img`
  margin: 0px;
  padding: 0px;
`

const ButtonDescription = styled(Link)`
  font-family: Source Code Pro;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.white};
  flex: none;
  background: ${colors.blue};
  padding: 8px 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 0px;
  background-position: bottom 100%;
`