import React from 'react'
import styled from 'styled-components'
import QuoteDivider from './QuoteDivider'
import { useStaticQuery, graphql } from 'gatsby'
import { CaseStudyCard } from './CaseStudyCard'
import Container from './Container'
import {colors, device} from './styles/GlobalStyles'

import GreenTick from "../assets/images/greenTickIcon.svg"
import BluetelLogo from "../assets/images/bluetel-glyph.svg"
import BgShapes1 from '../assets/images/shapes1.svg'
import BgShapes2 from '../assets/images/shapes2.svg'

interface VersionProps {
  mobileVersion?: Boolean
}

const HonestySection = () => {

const data = useStaticQuery(graphql`
  query HonestyQuoteQueryAndCaseStudyHonestyQuery {
    firstQuery: allContentfulQuote (filter: {id: {eq: "61d5dd20-11e6-5e1e-8b2e-1c2b6cb822e7"}}) {
      edges {
        node {
          id
          title
          authorName
          authorQuote
          jobTitle
          authorIcon {
            file {
              url
            }
          }
        }
      }
    }
  }
`
)

const HonestyQuoteData = data.firstQuery.edges

  return (
    <Container>
      <PragmatismContainer>
        <PragWrapper>
          <TextWrapper>
            <TitleWrapper>
              <BorderWrapper>
                <BorderNumber>
                  3
                </BorderNumber>
              </BorderWrapper>
              <TitleH1 id='Honesty'>
                Honesty
              </TitleH1>
              </TitleWrapper>
              <SubTitleH2>
                Setting accurate expectations
              </SubTitleH2>
              <Description>
                We believe in delivering on what we say: to do this, we need to be realistic and accurate about 
                what’s possible within the timeframe and budget. We’ll guide you through the implications of 
                various design and tech decisions on budget, scope and timeline.
                <br></br><br></br>
                By setting up flexible, often-reviewed Agile sprints and epics, we’re able to move fast and flexibly 
                with changes in scope.
              </Description>
              <ListWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>Agile Development</ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>Continuous deployment</ListText>
                </ListHorizontalWrapper>
              </ListWrapper>
              <ListWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>Extreme product & Dev expertise</ListText>
                </ListHorizontalWrapper>
              </ListWrapper>
          </TextWrapper>
          <GreenTickImg src={GreenTick}/>
        </PragWrapper>

        <QuoteWrapper>
          <BgPicture2 src={BgShapes2}/>                              
            <QuoteDivider
              image = {HonestyQuoteData[0].node.authorIcon.file.url}
              quote = {HonestyQuoteData[0].node.authorQuote}
              author = {HonestyQuoteData[0].node.authorName}
              jobTitle = {HonestyQuoteData[0].node.jobTitle}
            />
            <BgPicture src={BgShapes1}/>
        </QuoteWrapper>                                  
      </PragmatismContainer>
    </Container>
  )
}

export default HonestySection

const PragmatismContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 44px 6px 44px 6px;

  @media ${device.laptop} {
    padding: 80px 0px 0px;
  }
`

const PragmatismVerticalCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;

  @media ${device.laptop} {
    flex-direction: row;
    margin: 32px 0px 0px 0px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  margin: 0px;

  @media ${device.laptop} {
    width: 755px;
    margin-right: 93px;
  }
`

const PragWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${device.laptop} {
    flex-direction: row;
    width: 1242.03px;
  }
`

const GreenTickImg = styled.img`
  width: 123px;
  height: 123px;
  margin: 68px 0px 72px 0px;

  @media ${device.laptop} {
    width: 151px;
    height: 151px;
    left: 118.5px;
    margin: 0px 0px 0px 100px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 0px;
  position: static;
  height: 40px;
  flex: none;
`

const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  position: static;
  width: 32px;
  height: 32px;
  border: 3px solid ${colors.green};
  box-sizing: border-box;
  flex: none;
  margin-right: 24px;

  @media ${device.laptop} {
    width: 40px;
    height: 40px;
}
`
  
const BorderNumber = styled.div`
  position: static;
  width: 9px;
  height: 27px;
  left: 15.5px;
  top: 6.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.green};
  flex: none;
  margin: 10px 0px;

  @media ${device.laptop} {
    font-size: 18px;
  }
`
  
const TitleH1 = styled.div`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.white};
  flex: none;

  @media ${device.laptop} {
    font-size: 18px;
    height: 27px;
    width: 325px;
  }
`

const SubTitleH2 = styled.h2`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: ${colors.white};
  flex: none;
  margin: 8px 0px;

  @media ${device.laptop} {
    font-size: 54px;
    height: 80px;
    width: 755px;
    line-height: 148%;
  }
`

const Description = styled.p`
  font-family: "Raleway";
  font-size: 18px;
  line-height: 148%;
  color: ${colors.lightGrey};
  flex: none;
  align-self: stretch;
  padding: 0px;
  margin-bottom: 32px;

  @media ${device.laptop} {
    width: 755px;
    height: 202px;
    font-size: 21px;
  }
`

const ListWrapper =  styled.div`
  list-style: none;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 755px;
    flex-direction: row;
}
`

const ListHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  flex: none;
  margin: 0px 0px 12px 0px;

  @media ${device.laptop} {
    margin: 0px 32px 16px 0px;
  }
`

const ListImage = styled.img`
  width: 16px;
  height: 16px;
  margin: 0px 24px 0px 0px;
  padding: 0px;
`

const ListText = styled.div`
  text-transform: uppercase;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.white};
  text-align: left;
`

const CaseStudyTitleWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;

  @media ${device.laptop} {
    margin-top: 80px;
    height: 21px;
  }
  ${({ active }: any) =>
    active == false &&
    `
    display: none;
  `}
`

const CaseStudySubtitle = styled.div<VersionProps>`
  height: 21px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 148%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-right: 16px;
  color: ${colors.grey};
  display: ${props => props.mobileVersion == false ? 'none' : 'inline-block'};

  @media ${device.laptop} {
    display: ${props => props.mobileVersion == true ? 'none' : 'inline-block'};
  }
`

const LineBorder = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    position: static;
    width: 857px;
    border: 1px solid ${colors.blueGrey};
  }
`

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  color: #fff;
  justify-content: space-between;

  @media ${device.laptop} {
    flex-direction: row;
    padding-bottom: 80px;
  }
`

const BgPicture = styled.img`
  width: 82.5px;
  height: 69.5px; 
  margin: 0px -200px 0px 0px;

  @media ${device.laptop} {
    width: 165px;
    height: 139px;
    margin: 0px 0px 60px 0px;
  }
`

const BgPicture2 = styled.img`
  width: 79px;
  height: 41.5px;
  margin: 0px;
  margin: 0px 0px -110px -200px;

  @media ${device.laptop} {
    width: 158px;
    height: 83px;
    margin: 30px 0px 0px 0px;
  }
`
