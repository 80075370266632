import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import {colors, device} from './styles/GlobalStyles'
import { Link } from 'gatsby';

import BinaryDividerImg from "../assets/images/sectionBinaryDivider.svg"

const PragmatismDivider = () => {
  return (
    <>
      <BinaryDivider>
        <Container>
          <Description>
            Our approach is founded on <SectionLink to='/#PragmatismSection'>pragmatism</SectionLink>, <br></br> 
            <SectionLink to='/#UncompromisingStandardsSection'>uncompromising standards</SectionLink>
            &nbsp;and&nbsp;
            <SectionLink to='/#Honesty'>honesty</SectionLink>.
          </Description>
        </Container>
      </BinaryDivider>
    </>
  )
}

export default PragmatismDivider

const BinaryDivider = styled.div`
  display: flex;
  background-image: url(${BinaryDividerImg});
  background-origin: border-box;
  background-repeat: repeat-x;
  background-position: bottom;
`

const Description = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 140%;
  color: ${colors.white};
  padding: 32px 0px 32px 0px;

  @media ${device.laptop} {
    font-size: 40px;
    line-height: 148%;
    padding: 48px 0px 56px 0px;
  }

`

const SectionLink = styled(Link)`
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 140%;
  color: ${colors.green};
  
  &:hover{
      transition: 0.2s ease-out;
    }

  @media ${device.laptop} {
    font-size: 40px;
    line-height: 148%;
    }
`