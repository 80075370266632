import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import { Link } from 'gatsby'
import {colors, device} from './styles/GlobalStyles'
import EngineeringLogo from "../assets/images/engineeringIcon.svg"
import MobileTeamImg from "../assets/images/engineeringImageMobile.png"
import TeamImg from "../assets/images/engineeringImage.svg"
import Arrow from "../assets/images/greenArrow.svg"

const EngineeringLedSection = () => {
  return (
    <Container>
      <SectionContainer>
        <ContentContainer>
            <TextWrapper>
              <Logo src={EngineeringLogo}/>
              <TitleH1 id ='EngineeringLedSection'>
                Engineering-led
              </TitleH1>
              <Description>
                Our engineering and product teams are at the forefront of delivery and client communications. 
                Our engineers demonstrate extreme ability and competency in their work, being able to faciliate 
                important decisions collaboratively with clients. <br></br><br></br>
                Never taking anything for granted - and always with at least one eye on user experience - we approach 
                every project critically, challenging internal thinking to help you look at your business objectives 
                from different perspectives.
              </Description>
              <NavLink to='https://www.linkedin.com/company/bluetel-solutions-ltd'>
                Find us on LinkedIn
                <ArrowIcon src={Arrow} />
              </NavLink>
            </TextWrapper>
            <GroupImg src={TeamImg}/>
            <MobileGroupImg src={MobileTeamImg}/>
        </ContentContainer>
      </SectionContainer>
    </Container>
  )
}

export default EngineeringLedSection

const SectionContainer = styled.div`
  background: linear-gradient(180deg, #1F0D65 0%, #1D0E58 100%);
  box-shadow: 0px 4px 32px rgba(10, 4, 33, 0.6);

  @media ${device.laptop} {
    width: 1312px;
    height: 737px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media ${device.laptop} {
    flex-direction: row;
    padding: 65px 64px 0px 64px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
  padding: 25px 24px 0px 24px;

  @media ${device.laptop} {
    width: 572px;
    padding: 0px;
  }
`

const Logo = styled.img`
  width: 43.13px;
  height: 41.35px;

  @media ${device.laptop} {
    width: 57.5px;
    height: 55.14px;
  }
`

const TitleH1 = styled.h1`
  position: static;
  font-family: Raleway;
  font-weight: 600;
  font-size: 32px;
  line-height: 148%;
  color: ${colors.white};
  margin: 0px;
  padding: 0px;

  @media ${device.laptop} {
    font-size: 40px;
  }
`

const Description = styled.p`
  font-family: Poppins;
  font-size: 18px;
  line-height: 160%;
  color: ${colors.lightGrey};
  margin: 24px 0px;
  padding: 0px;

  @media ${device.laptop} {
    font-size: 20px;
    color: ${colors.grey};
  }
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 148%;
  text-decoration-line: underline;
  color: ${colors.green};
  text-align: center;
`

const MobileGroupImg = styled.img`
  width: 100%;
  height: 100%;
  margin-top: 40px;

  @media ${device.laptop} {
    display: none;
  }
`

const GroupImg = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 40px;
  }
`
  
const ArrowIcon = styled.img`
  width: 23px;
  height: 22px;
  margin: 0px;
  margin: 0px 0px 0px 17px;
`