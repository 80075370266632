import * as React from "react"
import styled from 'styled-components'
import {colors, device} from './styles/GlobalStyles'

interface CardProps {
  Image?: string
  Body: string
}

export const StandardPointCard: React.FC<CardProps> = ({ Image, Body }) => (
  <>
    <StyledContainer className="card">
      <StyledPhoto className="image" src={Image}/>
      <Description className="body">
        {Body}
      </Description>
    </StyledContainer>
  </>
)

const StyledContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  overflow: hidden;
  background: transparent;
  color: ${colors.white};
  cursor: pointer;
  border: 0px;
  outline: 0;
`

const Description = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: ${colors.white};
  flex: none;
  order: 0;
  align-self: stretch;
  margin: 0px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 21px;
  }
`

const StyledPhoto = styled.img`
  position: static;
  width: 80px;
  height: 80px;
  filter: drop-shadow(0px 0px 32px rgba(3, 12, 32, 0.6));
  flex: none;
  margin-bottom: 16px;
  align-self: center;

  @media ${device.laptop} {
    margin-bottom: 20px;
    width: 96px;
    height: 96px;
  }
`