import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Container from './Container'
import { CtaCard } from './CtaCard'
import { device } from './styles/GlobalStyles'

const CtaCardsSection = () => {
  const data = useStaticQuery(graphql`
  query CtaCardsQuery {
    allContentfulCtaCard {
      edges {
        node {
          title
          url
          button
          icon {
            file {
              url
            }
          }
          body {
            raw
          }
        }
      }
    }
  }
` 
  )
  
  const ctaCardData = data.allContentfulCtaCard.edges 

  return (
    <Container>
      <CardItems>
        {ctaCardData.map((card:any) => {
          return(
            <CtaCard 
              Image = {card?.node?.icon?.file?.url ? card.node.icon.file.url : ''}
              Title = {card?.node?.title ? card.node.title : ''}
              Body = {card.node.body.raw}
              ButtonText = {card.node.button}
              ButtonURL = {card?.node?.url ? card.node.url : ''}
            />
          )
        })}
      </CardItems>
    </Container>
  )
}

export default CtaCardsSection

const CardItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 0px 0px 63px;

  @media ${device.laptop} {
    padding: 60px 0px 128px;
    flex-direction: row;
  }
`
