import React from 'react'
import styled from 'styled-components'
import {colors, device} from './styles/GlobalStyles'

interface QuoteProps {
  image?: string
  quote: string
  author: string
  jobTitle: string
}

const QuoteDivider: React.FC<QuoteProps> = ({ image, quote, author, jobTitle }) => {
  return (
    <PragmatismVertical>
      <LineBorder/>
      <ProfilePictureWrapper>
        <ProfilePicture className="image" src={image} />
      </ProfilePictureWrapper>
      <Description className="quote"> 
        {quote}
      </Description>
      <AuthorQuote className="author">
        {author}
      </AuthorQuote>
      <JobTitle className="jobTitle"> 
        {jobTitle}
      </JobTitle> 
    </PragmatismVertical>
  )
}

export default QuoteDivider

const PragmatismVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    max-width: 1440px;
  }
`

const ProfilePictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Description = styled.p`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: ${colors.white};
  flex: none;
  margin-top: 20px;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 24px;
    font-size: 32px;
    width: 843px;
  }
`

const AuthorQuote = styled.p`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 148%;
  text-align: center;
  color: ${colors.white};
  flex: none;
  margin-bottom: 6px;

  @media ${device.laptop} {
    font-size: 21px;
    width: 840px;
  }
`

const JobTitle = styled.p`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  text-align: center;
  color: ${colors.grey};
  flex: none;
  order: 1;
  margin-bottom: 0px;

  @media ${device.laptop} {
    font-size: 18px;
    width: 840px;
    margin-bottom: 12px;
  }
`

const ProfilePicture = styled.img`
  width: 80px;
  height: 80px;
  margin: 0px;    
  border-radius: 50%;
`

const LineBorder = styled.div`
  width: 128px;
  border: 1px solid ${colors.darkPurple};
  margin-top: 45px;
  margin-bottom: 32px;

  @media ${device.laptop} {
    width: 480px;
    margin-top: 32px;
    margin-bottom: 64px;
  }
`