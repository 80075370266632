import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import { Link } from 'gatsby'
import {colors, device} from './styles/GlobalStyles'

import CloudLogo from "../assets/images/cloud.svg"
import TeamImg from "../assets/images/team-image-2.png"
import Arrow from "../assets/images/greenArrow.svg"

const CloudSection = () => {
  return (
    <Container>
      <MobileContainer>
      <SectionContainer>
        <ContentContainer>
          <GroupImg src={TeamImg}/>
          <MobileGroupImg src={TeamImg} />
          <TextWrapper>
            <Logo src={CloudLogo}/>
            <TitleH1 id='CloudSection'>
              Cloud tech & transformation
            </TitleH1>
            <Description>
              True cloud transformation doesn’t just move your existing infrastructure into the cloud.  <br></br><br></br>
              It can transform your whole operating model, redefine customer experience, enable better 
              ways of working and open up new opportunities. In short, it can drive significant and 
              measurable competitive advantage.
            </Description>
            <NavLink to='/services#CloudTransformationSection'>
              Read about the true benefits of cloud transformation
              <ArrowIcon src={Arrow} />
            </NavLink>
          </TextWrapper>
        </ContentContainer>
      </SectionContainer>
      </MobileContainer>
    </Container>
  )
}

export default CloudSection

const MobileContainer = styled.div`
  display: flex;
  margin: 64px 0px 88px 0px;

  @media ${device.laptop}{
    margin: 0px;
  }
`

const SectionContainer = styled.div`
  display: flex;
  background: linear-gradient(180deg, #1F0D65 0%, #1D0E58 100%);
  box-shadow: 0px 4px 32px rgba(10, 4, 33, 0.6);

  @media ${device.laptop} {
    background: none;
    box-shadow: none;
    width: 1312px;
    height: 737px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media ${device.laptop} {
    flex-direction: row;
    padding: 66px 0px 128px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 0;
  padding: 33px 24px 0px;

  @media ${device.laptop} {
    width: 1312px;
    margin: 0px 0px 0px 26px;
    padding: 65px 0px 0px 0px;
    order: 1;
  }
`

const Logo = styled.img`
  width: 60px;
  height: 40px;
  margin: 0px 0px 17px 0px ;

  @media ${device.laptop} {
    margin: 0px 0px 24px 0px;
  }
`

const TitleH1 = styled.h1`
  position: static;
  height: 84px;
  font-family: Raleway;
  font-weight: 600;
  font-size: 32px;
  line-height: 148%;
  color: ${colors.white};
  margin: 0px;
  padding: 0px;

  @media ${device.laptop} {
    font-size: 40px;
    height: 59px;
  }
`

const Description = styled.p`
  font-family: Poppins;
  font-size: 18px;
  line-height: 160%;
  color: ${colors.lightGrey};
  margin: 24px 0px;
  padding: 0px;

  @media ${device.laptop} {
    font-size: 20px;
    width: 572px;
    color: ${colors.grey};
  }
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 148%;
  text-decoration-line: underline;
  color: ${colors.green};
  align-items: center;
  padding: 0px;
`

const MobileGroupImg = styled.img`
  width: 100%;
  order: 1;
  margin-top: 40px;

  @media ${device.laptop} {
    display: none;
  }
`

const GroupImg = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    height: 100%;
    width: 90%;
    order: 0;
    margin: 0px;
  }
`
  
const ArrowIcon = styled.img`
  width: 23px;
  height: 22px;
  margin: 0px 0px 0px 17px;
`