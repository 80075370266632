import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import LargeCaseStudyCard from './LargeCaseStudyCard'
import { StandardPointCard } from './StandardPointCard'
import Container from './Container'
import {colors, device} from './styles/GlobalStyles'

import BluetelLogo from "../assets/images/bluetel-glyph.svg"
import ChartImageMobile from "../assets/images/test-chart-mobile.svg"
import ChartImage from "../assets/images/test-chart.svg"
import BinaryDividerImg from "../assets/images/sectionBinaryDivider.svg"

interface VersionProps {
  mobileVersion?: Boolean
}

const StandardsSection = () => {

  const data = useStaticQuery(graphql`
  query CaseStudyStandardsQueryAndUncompromisingStandardsCaseStudyCardQuery {
    firstQuery: allContentfulCaseStudy(limit: 3,filter: {section: {eq: "Standards"}}) {
      edges {
        node {
          id
          title
          summary
          slug
          companyIcon {
            file {
              url
            }
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
    secondQuery: allContentfulStandardPointCard {
      edges {
        node {
          id
          title
          body
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`
  )

const caseStudyStandardsData = data.firstQuery.edges
const standardPointCardData = data.secondQuery.edges

  return (
    <>
      <BinaryDivider>
        <Container>
          <SectionContainer>
            <PragWrapper>
              <ChartImgMobile src={ChartImageMobile} />
              <ChartImg src={ChartImage}/>
                <TextWrapper>
                  <TitleWrapper>
                    <BorderWrapper>
                      <BorderNumber>
                        2
                      </BorderNumber>
                    </BorderWrapper>
                    <TitleH1 id='UncompromisingStandardsSection'>
                      Uncompromising Standards
                    </TitleH1>
                    </TitleWrapper>
                    <SubTitleH2>
                      Minimal technical debt.
                    </SubTitleH2>
                    <Description>
                      Moving fast doesn’t mean creating a mess. 
                      Our engineers write clean, readable and tested code, making it simpler for others to pick up and get going quickly. 
                      This saves you time, money and reduces problems in the short and long-term.
                    </Description>
                    <ListWrapper>
                      <ListHorizontalWrapper>
                        <ListImage src={BluetelLogo}/>
                        <ListText>Test-driven development</ListText>
                      </ListHorizontalWrapper>
                      <ListHorizontalWrapper>
                        <ListImage src={BluetelLogo}/>
                        <ListText>Continuous integration</ListText>
                      </ListHorizontalWrapper>
                    </ListWrapper>
                    <ListWrapper>
                      <ListHorizontalWrapper>
                        <ListImage src={BluetelLogo}/>
                        <ListText>Peer-reviewed code</ListText>
                      </ListHorizontalWrapper>
                    </ListWrapper>
                </TextWrapper>
            </PragWrapper>
            <VerticalStandardPoints>
              {standardPointCardData.slice(0,3).map((caseCard: any) => {
                return(
                  <StandardPointCard
                    Image = {caseCard.node.image.file.url}
                    Body = {caseCard.node.body}
                  />
                )
              })} 
            </VerticalStandardPoints>
            {/* <CaseStudyTitleWrapper active={caseStudyStandardsData.length > 0}>
              <CaseStudySubtitle style={{ color: '#CFD8EA' }}>
                Case studies
              </CaseStudySubtitle>
              <CaseStudySubtitle style={{ color: '#3D5386' }}>
                /
              </CaseStudySubtitle>
              <CaseStudySubtitle style={{ color: '#FFFFFF', width: '150px', alignItems: 'center'}} mobileVersion={true}>
                uncompromising standards
              </CaseStudySubtitle>
              <CaseStudySubtitle style={{ color: '#FFFFFF', marginRight: '35px' }} mobileVersion={false}>
                demonstrating uncompromising standards
              </CaseStudySubtitle>
              <LineBorder />
            </CaseStudyTitleWrapper>
            <VerticalCards>
              {caseStudyStandardsData.map((caseCard: any) => {
                return(
                  <LargeCaseStudyCard
                    Image = {caseCard?.node?.image?.file?.url ? caseCard.node.image.file.url : ''}
                    Body = {caseCard.node.summary}
                    Logo = {caseCard?.node?.companyIcon?.file?.url ? caseCard.node.companyIcon.file.url : ''}
                    Slug = {caseCard?.node?.slug ? caseCard.node.slug : ''}
                  />
                )
              })} 
            </VerticalCards> */}
          </SectionContainer>
        </Container>
      </BinaryDivider>
    </>
  )
}
  
export default StandardsSection

const BinaryDivider = styled.div`
  display: flex;
  background-image: url(${BinaryDividerImg});
  background-position: bottom;
  background-origin: border-box;
  background-repeat: repeat-x;
`

const SectionContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  position: relative;
  color: ${colors.white};
  flex-direction: column;
  padding: 44px 6px 44px 6px;

  @media ${device.laptop} {
    padding: 64px 0px 80px 0px;
  }
`

const VerticalCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptop} {
    margin-top: 32px;
    flex-direction: row;
  }
`

const VerticalStandardPoints = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  height: 482px;

  @media ${device.laptop} {
    margin-top: 80px;
    flex-direction: row;
    height: 200px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  flex: none;
  order: 0;

  @media ${device.laptop} {
    width: 755px;
    order: 1;
  }
`
  
const PragWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${device.laptop} {
    flex-direction: row;
    width: 1242.03px;
  }
`

const ChartImgMobile = styled.img`
  position: static;
  width: 327px;
  height: 356px;  
  flex: none;
  margin: 64px 0px 40px 0px;
  order: 1;

  @media ${device.laptop} {
    display: none;
  }
`

const ChartImg = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    width: 477px;
    height: 324px; 
    margin-right: 80px;
    order: 0;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  position: static;
  height: 40px;
  flex: none;
`
  
const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  position: static;
  width: 32px;
  height: 32px;
  border: 3px solid ${colors.green};
  box-sizing: border-box;
  flex: none;
  margin-right: 24px;

  @media ${device.laptop} {
    width: 40px;
    height: 40px;
}
`
  
const BorderNumber = styled.div`
  position: static;
  width: 9px;
  height: 27px;
  left: 15.5px;
  top: 6.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.green};
  flex: none;
  margin: 10px 0px;

  @media ${device.laptop} {
    font-size: 18px;
  }
`
  
const TitleH1 = styled.div`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.white};
  flex: none;
  width: 175px;

  @media ${device.laptop} {
    font-size: 18px;
    height: 27px;
    width: 325px;
  }
`
  
const SubTitleH2 = styled.h2`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 148%;
  color: ${colors.white};
  flex: none;
  margin: 8px 0px;

  @media ${device.laptop} {
    font-size: 54px;
    width: 575px;
    height: 95px;
  }
`
  
const Description = styled.p`
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 148%;
  color: ${colors.lightGrey};
  flex: none;
  align-self: stretch;
  margin-bottom: 32px;

  @media ${device.laptop} {
    font-size: 21px;
    width: 755px;
    height: 124px;
  }
`

const ListWrapper =  styled.div`
  list-style: none;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 755px;
    flex-direction: row;
  }
`

const ListHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  flex: none;
  margin-right: 32px;
  margin-bottom: 12px;

  @media ${device.laptop} {
    margin-bottom: 16px;
  }
`

const ListImage = styled.img`
  width: 16px;
  height: 16px;
  margin: 0px;
  margin-right: 24px;
`

const ListText = styled.div`
  text-transform: uppercase;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.white};
  flex: none;
  text-align: left;
`
  
const CaseStudyTitleWrapper = styled.div<{ active: boolean }>`
  display: flex; 
  flex-direction: row;
  margin: 74px 0px 0px 0px;
  align-items: center;
  height: 50px;

  @media ${device.laptop} {
    position: static;
    max-width: 1440px;
    height: 21px;
    flex: none;
    margin: 100px 0px 0px 0px;
  }
  ${({ active }: any) =>
    active == false &&
    `
    display: none;
  `}
`
  
const CaseStudySubtitle = styled.div<VersionProps>`
  position: static;
  height: 21px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 148%;
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.grey};
  margin-right: 16px;
  display: ${props => props.mobileVersion == false ? 'none' : 'flex'};

  @media ${device.laptop} {
    display: ${props => props.mobileVersion == true ? 'none' : 'flex'};
    text-align: center;
  }
`
  
const LineBorder = styled.div`
  display: none;

  @media ${device.laptop} {
  display: flex;
  position: static;
  width: 756px;
  border: 1px solid ${colors.blueGrey};
  }
`