import React from 'react'
import styled from 'styled-components'
import { Card } from './CompanyCard'
import { RoughNotation } from "react-rough-notation"
import { useStaticQuery, graphql } from 'gatsby'
import Container from './Container'
import {colors, device} from './styles/GlobalStyles'
import { Link } from 'gatsby'

import HeroImage from "../assets/images/heroimage-round.png"
import BookIcon from "../assets/images/book.svg"
import Arrow from "../assets/images/arrow.svg"
import Arrow2 from "../assets/images/mobileArrow.png"
import VerticalBinary from "../assets/images/verticalbinary.svg"
import MobileBinary from "../assets/images/mobileCaseBinary.svg"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query CaseStudyHeroQuery {
      allContentfulCompanyCardCta {
        edges {
          node {
            id
            title
            content
            numberOfCustomers
            authorName
            companyIcon {
              file {
                url
              }
            }
            authorIcon {
              file {
                url
              }
            }
          }
        }
      }
    }
` 
  )
    
  const caseStudiesHeroData = data.allContentfulCompanyCardCta.edges 

  return (
    <HeroContainer>
      <Container>
        <BgImageAndTextWrapper>
          <HeroItems>
            <TitleWrapper>
              <HeroH1>Strategize</HeroH1>
                <RoughNotation type ="highlight" show={true} color="#004CFF" animationDelay={200} >
                  <RoughNotationH1>
                    Transform
                  </RoughNotationH1>
                </RoughNotation>
            </TitleWrapper>
            <HeroP>Bluetel is <SectionLink to='/#EngineeringLedSection'>engineering-led</SectionLink> technical agency, expert at delivering business value through <SectionLink to='/#CloudSection'>cloud technology</SectionLink>.</HeroP>
            <Divider/>
            <List>
              <ListItem>
                <CaseStudyLink to='/case-studies'>Case Studies</CaseStudyLink>
              </ListItem>
              <ListItemTwo>
                <CaseStudyLink to='/case-studies'>Discover how we address technical challenges for our clients</CaseStudyLink>
              </ListItemTwo>
            </List>
            <MobileBinaryImg src={MobileBinary}/>
            {/* <HeroButtonItemsMobile>
              {caseStudiesHeroData.slice(-3).map((caseStudy:any) => {
                return(
                  <Card 
                    Image = {caseStudy.node.companyIcon.file.url}
                    Title = {caseStudy.node.title}
                    Body = {caseStudy.node.content}
                    Customers = {caseStudy.node.numberOfCustomers}
                    AuthorImg = {caseStudy.node.authorIcon.file.url}
                    Author = {caseStudy.node.authorName}
                  />
                )
              })}
            </HeroButtonItemsMobile>
            <HeroButtonItems>
              {caseStudiesHeroData.slice(-4).map((caseStudy:any) => {
                return(
                  <Card 
                    Image = {caseStudy.node.companyIcon.file.url}
                    Title = {caseStudy.node.title}
                    Body = {caseStudy.node.content}
                    Customers = {caseStudy.node.numberOfCustomers}
                    AuthorImg = {caseStudy.node.authorIcon.file.url}
                    Author = {caseStudy.node.authorName}
                  />
                )
              })}
            </HeroButtonItems> */}
          </HeroItems>
        </BgImageAndTextWrapper>
      </Container>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
  background: linear-gradient(180deg, #190D47 0%, #311077 100%);
  box-shadow: 0px 8px 32px rgba(9, 4, 30, 0.6);
  margin-top: -80px;
  color: ${colors.white};
`

const BgImageAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.laptop} {
    background-image: url(${HeroImage});
    background-position: top right, center center;
    background-origin: border-box;
    background-repeat: no-repeat;
  }
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px 0px 28px 0px;
  text-align: center;
  align-items: center;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 96px 0px 58px 0px;
  }
`

const HeroButtonItemsMobile = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    display: none;
  }
`

const HeroButtonItems = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
  }
`

const HeroH1 = styled.h1`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-size: 54px;
  letter-spacing: 0px;
  font-weight: bold;
  line-height: 130px;
  background: url(${Arrow2}) no-repeat left center;
  background-position: 120px 100px;
  margin: 0px;

  @media ${device.laptop} {
    background: url(${Arrow}) no-repeat left center;
    background-position: 360px 25px;
    font-size: 70px;
    line-height: 80px;
    width: 423px;
  }
`

const RoughNotationH1 = styled.h1`
  position: static;
  font-family: "Raleway";
  font-style: normal; 
  font-size: 54px;
  font-weight: bold;
  margin: 0px;

  @media ${device.laptop} {
    font-size: 70px;
    width: 344px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0px 24px 0px;

  @media ${device.laptop} {
    align-items: left;
    flex-direction: row;
    width: 760px;
    margin: 32px 0px 8px 0px;
  }
`

const HeroP = styled.div`
  position: static; 
  font-size: 21px;
  padding-bottom: 32px;
  font-weight: 500;
  font-family: "Raleway";
  font-style: normal;
  color: ${colors.grey};
  
  @media ${device.laptop} {
    width: 740px;
    font-size: 24px;
  }
`

const SectionLink = styled(Link)`
  font-size: 21px;
  padding-bottom: 32px;
  font-weight: 500;
  font-family: "Raleway";
  font-style: normal;
  color: ${colors.green};

  @media ${device.laptop} {
    font-style: bold;
    font-family: "Raleway";
    font-size: 24px;
    }
`

const Divider = styled.div`
  width: 48px;
  border: 2px solid ${colors.blue};
  align-items: center;
  text-align: center;
  
  @media ${device.laptop} {
    align-items: left;
  }
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
`

const ListItem = styled.li`
  display: flex;
  background: url(${BookIcon}) no-repeat left center;
  background-position: -0px 2.5px;
  list-style: none;
  font-family: "Source Code Pro";
  font-size: 15px;
  padding: 5px 10px 15px 50px;
  margin: 28px 0 0;
  font-weight: bold;

  @media ${device.laptop} {
    padding: 5px 10px 0px 50px;
    margin: 32px 0px 0px 0px;
  }
`

const ListItemTwo = styled.li`
  display: none;

  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    background: url(${VerticalBinary}) no-repeat left center;
    background-position: 0px 0px;
    list-style: none;
    font-family: "Source Code Pro";
    position: static;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    padding: 5px 10px 25px 25px;
    margin-top: 32px;
    margin-left: 20px;
    color: ${colors.grey};
  }
`
const CaseStudyLink = styled(Link)`
  font: inherit;
  text-decoration: inherit;
  margin: 0px;
  color: inherit;
`
const MobileBinaryImg = styled.img`
  display: flex;
  width: 100%;

  @media ${device.laptop} {
    display: none;
  }

  
`