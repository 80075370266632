import * as React from 'react'

import Layout from '../components/layout'
import Hero from '../components/Hero'
import BusinessDivider from '../components/BusinessDivider'
import PragmatismDivider from '../components/PragmatismDivider'
import PragmatismSection from '../components/PragmatismSection'
import StandardsSection from '../components/StandardsSection'
import HonestySection from '../components/HonestySection'
import CtaCardsSection from '../components/CtaCardsSection'
import EngineeringLedSection from '../components/EngineeringLedSection'
import CloudSection from '../components/CloudSection'
import SEO from '../components/seo'

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Home - Bluetel Solutions" />
    <Hero/>
    <BusinessDivider/>
    <PragmatismDivider/>
    <PragmatismSection/>
    <StandardsSection/>
    <HonestySection/>
    <CtaCardsSection/>
    <EngineeringLedSection/>
    <CloudSection/>
  </Layout>
);

export default IndexPage;
