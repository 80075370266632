import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import {colors, device} from './styles/GlobalStyles'

import CompanyLogosMobile from "../assets/images/logos-mobile.png"
import CompanyLogosTwo from "../assets/images/company-logos2.svg"
import BinaryDividerImg from "../assets/images/businessBinaryDivider.svg"

const BusinessDivider = () => {
  return (
    <>
      <BinaryDivider>
        <Container>
          <BusinessContainer> 
            <Description>
              We work with startups to multi-national corporations 
            </Description>
            <Logos src={CompanyLogosTwo} />
            <LogosMobile src={CompanyLogosMobile} />
          </BusinessContainer>
        </Container>
      </BinaryDivider>
    </>
  )
}

export default BusinessDivider

const BusinessContainer = styled.div`
  display: flex;
  flex-direction: column;    
  justify-content: center;
  align-items: center;
`

const Description = styled.h1`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: ${colors.grey};
  margin: 40px 0px 16px 0px;

  @media ${device.laptop} {
    width: 1312px;
    font-size: 32px;
    line-height: 148%;
  }
`

const BinaryDivider = styled.div`
  display: flex;
  background-image: url(${BinaryDividerImg});
  background-origin: border-box;
  background-repeat: repeat-x;
  background-position: bottom;
`

const Logos = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    width: 1312px;
    height: 50%;
    filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    -ms-filter: grayscale(80%);
    -o-filter: grayscale(80%);
    filter: gray;
    margin-bottom: 36px;
  
    summary{
      background-color: transparent;
    }
  }
`
const LogosMobile = styled.img`
  margin-bottom: 32px;
  mix-blend-mode: luminosity;

  @media ${device.laptop} {
    display: none;
  }
`