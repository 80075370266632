import React from 'react'
import styled from 'styled-components'
import QuoteDivider from './QuoteDivider'
import { useStaticQuery, graphql } from 'gatsby'
import { CaseStudyCard } from './CaseStudyCard'
import Container from './Container'
import {colors, device} from './styles/GlobalStyles'

import Image from "../assets/images/code-bg.svg"
import FlowImage from "../assets/images/flow-chart.svg"
import BluetelLogo from "../assets/images/bluetel-glyph.svg"
import BgShapes1 from '../assets/images/shapes1.svg'
import BgShapes2 from '../assets/images/shapes2.svg'
import BinaryDividerImg from "../assets/images/sectionBinaryDivider.svg"

interface VersionProps {
  mobileVersion?: Boolean
}

const PragmatismSection = () => {
  const data = useStaticQuery(graphql`
    query PragmatismQuoteQueryAndCaseStudyPragmatismQuery {
      firstQuery: allContentfulQuote (filter:
        {id:
          {eq: "e06d5f37-32d1-553c-b1eb-846e7d02209d"}
        }
      ) {
        edges {
          node {
            id
            title
            authorName
            authorQuote
            jobTitle
            authorIcon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `
  )

const pragmatismQuoteData = data.firstQuery.edges

  return (
    <PragBackground>
      <Container>
        <PragmatismContainer>
          <PragWrapper>
            <TextWrapper>
              <TitleWrapper>
                <BorderWrapper>
                  <BorderNumber>
                    1
                  </BorderNumber>
                </BorderWrapper>
                <TitleH1 id='PragmatismSection'>
                  Pragmatism
                </TitleH1>
              </TitleWrapper>
              <SubTitleH2>
                Context is key.
              </SubTitleH2>
              <Description>
                We prioritise delivery, being scrupulous about product and technical 
                decisions. We design contextual solutions to the scale of the problem and use 
                case, not in excess. Then, relentlessly execute using modern delivery 
                methods without compromise. 
              </Description>
              <ListWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>Design Thinking</ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>User-centered delivery</ListText>
                </ListHorizontalWrapper>
              </ListWrapper>
              <ListWrapper>
                <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>MVP scoping & Development</ListText>
                </ListHorizontalWrapper>
              </ListWrapper>
            </TextWrapper>
            <FlowChart src={FlowImage}/>
          </PragWrapper>
          <QuoteWrapper>
            <BgPicture src={BgShapes1}/>                              
            <QuoteDivider
              image = {pragmatismQuoteData[0].node.authorIcon.file.url}
              quote = {pragmatismQuoteData[0].node.authorQuote}
              author = {pragmatismQuoteData[0].node.authorName}
              jobTitle = {pragmatismQuoteData[0].node.jobTitle}
            />
            <BgPicture2 src={BgShapes2}/>
          </QuoteWrapper>                                  
        </PragmatismContainer>
      </Container>
    </PragBackground>
  )
}

export default PragmatismSection

const PragmatismContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  position: relative;
  color: ${colors.white};
  flex-direction: column;
  padding: 32px 6px 100px 6px;

  @media ${device.laptop} {
    width: 1312px;
    padding: 64px 0px 80px 0px;
  }
`

const PragmatismVerticalCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptop} {
    flex-direction: row;
    margin-top: 32px;
  }
`

const PragBackground = styled.div`
  display: flex;
  background-image: url(${Image}), url(${BinaryDividerImg});
  background-position: top, bottom;
  background-origin: border-box;
  background-repeat: repeat-x;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  height: 372px;
  flex: none;

  @media ${device.laptop} {
    margin-right: 93px;
  }
`

const PragWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${device.laptop} {
    flex-direction: row;
  }
`

const FlowChart = styled.img`
  height: 175px;
  margin: 90px 0px 0px 0px;

  @media ${device.laptop} {
    left: 912px;
    width: 394.03px;
    height: 200px;
    margin: 0px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  position: static;
  height: 40px;
  flex: none;
`

const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  position: static;
  width: 32px;
  height: 32px;
  border: 3px solid ${colors.green};
  box-sizing: border-box;
  flex: none;
  margin-right: 24px;

  @media ${device.laptop} {
    width: 40px;
    height: 40px;
  }
`

const BorderNumber = styled.div`
  position: static;
  width: 9px;
  height: 20px;
  left: 15.5px;
  top: 6.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.green};
  flex: none;

  @media ${device.laptop} {
    font-size: 18px;
  }
`

const TitleH1 = styled.div`
  position: static;
  left: 64px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.white};
  flex: none;
  order: 1;

  @media ${device.laptop}{
    font-size: 18px;
    height: 27px;
  }
`

const SubTitleH2 = styled.h2`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: ${colors.white};
  flex: none;
  margin: 8px 0px;

  @media ${device.laptop} {
    font-size: 64px;
    line-height: 148%;
    height: 95px;
  }
`

const Description = styled.p`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 148%;
  color: ${colors.lightGrey};
  flex: none;
  align-self: stretch;
  margin-bottom: 32px;

  @media ${device.laptop} {
    font-size: 21px;
    width: 755px;
  }
`

const ListWrapper =  styled.div`
  list-style: none;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 720px;
    flex-direction: row;
  }
`

const ListHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  flex: none;
  margin-right: 32px;
  margin-bottom: 16px;
`

const ListImage = styled.img`
  width: 16px;
  height: 16px;
  margin: 0px;
  margin-right: 24px;
`

const ListText = styled.div`
  text-transform: uppercase;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.05em;
  color: ${colors.white};
  flex: none;
  order: 1;
  text-align: left;
`

const CaseStudyTitleWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  height: 21px;
  flex: none;
  margin-top: 55px;

  @media ${device.laptop} {
    margin-top: 80px;
  }
  
  ${({ active }: any) =>
    active == false &&
    `
    display: none;
  `}
`

const CaseStudySubtitle = styled.div<VersionProps>`
  position: static;
  height: 21px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 148%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.grey};
  margin-right: 16px;
  display: ${props => props.mobileVersion == false ? 'none' : 'inline-block'};

  @media ${device.laptop} {
    display: ${props => props.mobileVersion == true ? 'none' : 'inline-block'};
  }
}
`

const LineBorder = styled.div`
  position: static;
  width: 57px;
  border: 1px solid ${colors.blueGrey};

  @media ${device.laptop} {
    width: 893px;
  } 
`

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  color: ${colors.white};
  justify-content: space-between;

  @media ${device.laptop} {
    flex-direction: row;
  }
`

const BgPicture = styled.img`
  width: 82.5px;
  height: 69.5px; 
  margin: 0px 0px -120px -200px;

  @media ${device.laptop} {
    width: 165px;
    height: 139px;
    margin: 0px 0px 60px 0px;
  }
`

const BgPicture2 = styled.img`
  width: 79px;
  height: 41.5px;
  margin: 0px;
  margin: -90px -200px -0px -0px;

  @media ${device.laptop} {
    width: 158px;
    height: 83px;
    margin: 30px 0px 0px 0px;
  }
`